
























































import Vue from 'vue'
import Carousel from 'library/components/global/carousel/carousel'
import inViewportDirective from 'vue-in-viewport-directive'
import getFlavorsById from 'library/queries/craft/flavorsById.gql'
import hasPricespiderBtn from 'library/mixins/has-pricespider-btn'
import hasVideoModalBtn from 'library/mixins/has-video-modal-btn'
import { bundleRollupAll } from 'library/services/shopify/bundling/rollupProduct'


Vue.directive('in-viewport', inViewportDirective)

export default

	mixins: [ hasPricespiderBtn, hasVideoModalBtn ]

	components: { Carousel }

	props: block: Object

	data: -> products: []

	computed:
		layout: -> "layout-#{@block.layout}"
		video: -> [
			desktop: @block.ambientVideo
			mobile: @block.ambientVideo
		]

	# Fetch product data
	fetch: ->
		handles = @block.products?.map ({ slug }) -> slug
		ids = @block.products?.map ({ id }) -> id
		if @$cartEnabled
			@products = await @$storefront.getProductCards handles
			@products = await bundleRollupAll @products, { @$storefront }
		else
			@products = await @$craft.getEntries
				query: getFlavorsById
				variables: { ids }

			@products = @products.map (product) ->
				return unless product.assetGroup.length
				{
					...product
					image: product.assetGroup[0].image[0]
				}
